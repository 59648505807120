import React from "react";
import { ElfsightWidget } from 'react-elfsight-widget';
import './listings.css';

function Listings() {
  return (
    <div className="container-wrapper">
      <h1 className="title">Price List 2024</h1>
      <ElfsightWidget widgetId="af8caecb-e99e-483b-8024-8244e9788b03" />
    </div>
  );
}

export default Listings;