import React, { Suspense, lazy } from "react";
import { ElfsightWidget } from "react-elfsight-widget";

// Lazy load components
const SpliceElement = lazy(() => import("./splice"));
const GridContainer = lazy(() => import("./gridContainer"));
const Listings = lazy(() => import("./listings"));
const Contactparent = lazy(() => import("./contactParent"));
const Socials = lazy(() => import("./socials"));
const AboutSection = lazy(() => import("./about"));
const Quote = lazy(() => import("./quote"));
const Slideshow = lazy(() => import("./slideShow"));

// Create a simple loading component
const Loading = () => <div>Loading...</div>;

function Homepage() {
  return (
    <div>
      <ElfsightWidget style={{marginTop: "1%"}} widgetId="c6a27cf6-6681-4c2f-9ddf-12adebc1857e" />
      
      <Suspense fallback={<Loading />}>
        <SpliceElement />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <Quote />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <AboutSection />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <GridContainer />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <Listings />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <Slideshow />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <Contactparent />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <Socials />
      </Suspense>
    </div>
  );
}

export default Homepage;